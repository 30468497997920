import React from 'react';
import IconSidebar from './icons/IconSidebar';

interface HeaderProps {
  toggleSidebar: () => void;
  selectedModel: string;
  setSelectedModel: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<HeaderProps> = ({ toggleSidebar, selectedModel, setSelectedModel }) => {
  const handleModelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedModel(event.target.value);
  };

  return (
    <div className="flex justify-between items-center p-4 bg-gray-100 dark:bg-gray-900 fixed top-0 left-0 w-full z-20">
      <button onClick={toggleSidebar} className="text-2xl dark:text-gray-100">
        <IconSidebar className="w-8 h-8" />
      </button>
      <h1 className="text-2xl font-bold dark:text-gray-100 absolute left-1/2 transform -translate-x-1/2">{process.env.REACT_APP_BOT_NAME} v2.0</h1>
      <div className="flex items-center space-x-2">
        <label htmlFor="model-select" className="text-base dark:text-gray-400 font-bold">Model:</label>
        <select
          id="model-select"
          value={selectedModel}
          onChange={handleModelChange}
          className="p-2 bg-gray-200 dark:bg-gray-800 dark:text-gray-400 rounded-md"
        >
          <option value="x/llama3.2-vision:11b">Llama 3.2 11B</option>
          <option value="llama3.1">Llama 3.1</option>
          <option value="llama3.2:latest">Llama 3.2 3B Pi</option>
          <option value="llama3.2:1b">Llama 3.2 1B Pi</option>
          <option value="gemma2">Gemma 2</option>
          <option value="llava-llama3">Llava Llama 3</option>
          <option value="mistral-nemo">Mistral Nemo</option>
          <option value="gpt-4o">GPT-4o</option>
          <option value="gpt-4">GPT-4</option>
          <option value="gpt-4-32k">GPT-4-32k</option>
          <option value="gpt-3.5-turbo">GPT-3.5-Turbo</option>
          <option value="llama-3-8b-32k-4090">Llama-8B-32k</option>
        </select>
      </div>
    </div>
  );
};

export default Header;
