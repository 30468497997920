import React from 'react';
import { FiSun, FiMoon } from 'react-icons/fi';
import ToolkitSelector from './ToolkitSelector';
import ChatHistory from './ChatHistory';
import UserDropdown from './UserDropdown';
import { Message } from '../App'

interface SidebarProps {
  isSidebarVisible: boolean;
  toggleSidebar: () => void;
  isDarkMode: boolean;
  toggleDarkMode: () => void;
  selectedToolkit: string;
  setSelectedToolkit: (toolkit: string) => void;
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;  
}

const Sidebar: React.FC<SidebarProps> = ({
  isSidebarVisible,
  toggleSidebar,
  isDarkMode,
  toggleDarkMode,
  selectedToolkit,
  setSelectedToolkit,
  messages,
  setMessages
}) => {
  return (
    <div
      className={`fixed top-0 left-0 h-full bg-gray-200 dark:bg-gray-900 p-4 text-center transition-transform transform ${
        isSidebarVisible ? 'translate-x-0' : '-translate-x-full'
      }`}
      style={{ width: '250px', zIndex: 10, borderRight: 'none' }}
    >
      <div className="mt-16">
        <ToolkitSelector selectedToolkit={selectedToolkit} setSelectedToolkit={setSelectedToolkit} />
        <ChatHistory messages={messages} setMessages={setMessages} />
      </div>
      {/*<div className="absolute bottom-0 left-0 right-0 flex justify-between p-4">
        <UserDropdown />
        <button onClick={toggleDarkMode} className="text-2xl dark:text-gray-100">
          {isDarkMode ? <FiMoon /> : <FiSun />}
        </button>
      </div>*/}
    </div>
  );
};

export default Sidebar;
